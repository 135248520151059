<template>
  <div>
    <a-form-model
        ref="searchForm"
        :model="searchForm"
        class="search-form"
        v-bind="layout"
    >
      <a-row>
        <a-col :span="7">
          <a-form-model-item label="品牌">
            <a-select
                v-model="searchForm.brand_id"
                show-search
                option-filter-prop="children"
            >
              <a-select-option
                  :value="item.id"
                  v-for="item in brandSelectList"
                  :key="item.id"
              >
                {{ item.principal_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="7">
          <a-form-model-item label="经销商">
            <a-input allowClear v-model="searchForm.dealer_name" placeholder=""></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7">
          <a-form-model-item label="主播昵称">
            <a-input allowClear v-model="searchForm.nickname" placeholder=""></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="7">
          <a-form-model-item label="视频标题">
            <a-input allowClear v-model="searchForm.aweme_title" placeholder=""></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="7">
          <a-form-model-item label="发布时间">
            <a-range-picker allowClear style="width: 390px"
                            :disabled-date="pickerOptions.disabledDate"
                            :show-time="{ defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],}"
                            format="YYYY-MM-DD HH:mm:ss"
                            :placeholder="['开始时间', '结束时间']" v-model="searchForm.time" inputReadOnly
                            @change="onChangeDate"/>
            <!--<a-range-picker-->
            <!--    v-model="searchForm.time"-->
            <!--    :show-time="{ format: 'HH:mm:ss' }"-->
            <!--    :placeholder="['开始时间', '结束时间']"-->
            <!--    format="YYYY-MM-DD HH:mm:ss"-->
            <!--    inputReadOnly-->
            <!--    @change="onChangeDate"-->
            <!--    style="width: 340px"-->
            <!--/>-->
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7"></a-col>
        <a-col :span="7"></a-col>
        <a-col :span="7">
          <a-form-model-item>
            <div class="flex-wrap">
              <a-button class="ml-10p" icon="redo" @click="resetSearch"
              >重置
              </a-button>
              <a-button
                  class="ml-10p"
                  type="primary"
                  icon="search"
                  @click="searchClick"
              >
                搜索
              </a-button>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="header_message">
      <span>更新周期：24小时</span
      ><span class="updata_times">上次更新：{{ updataTime }}</span>
    </div>
    <a-table
        :columns="columns"
        :data-source="listData"
        :pagination="false"
        :rowKey="(record) => record.aweme_id"
        :loading="loading"
    >
      <div slot="awemeId" slot-scope="text, record" class="video_title">
        <div class="cover_box">
          <img :src="record.aweme_cover" alt=""/>
        </div>
        <div>
          <div class="aweme-title">{{ record.aweme_title || "-" }}</div>
          <div class="aweme-dealer" style="display: flex;align-items: center">
            主播： <img class="aweme_img" :src="record.avatar" alt=""/>{{ record.nickname || "-" }}
          </div>
          <div class="aweme-dealer">平台号：{{ record.code || "-" }}</div>
          <div class="aweme-dealer">发布时间：{{ record.publish_time || "-" }}</div>
        </div>
      </div>
      <span slot="digg_count" slot-scope="text">
        {{ text | changeNum }}
      </span>
      <span slot="comment_count" slot-scope="text">
        {{ text | changeNum }}
      </span>
      <span slot="share_count" slot-scope="text">
        {{ text | changeNum }}
      </span>
      <span slot="play_volume" slot-scope="text">
        {{ text | changeNum }}
      </span>
      <span slot="operation" slot-scope="text, record">
        <a @click="() => openVideo(record.aweme_url)" style="display: block"
        >播放视频</a
        >
        <a @click="() => openDetail(record.aweme_id)" style="display: block">查看详情</a>
        <a type="link" @click="favorite(record)" v-if="record.isCollection == 0">收藏视频</a>
        <a type="link" v-if="record.isCollection == 1" style="color: #8b8e92">已收藏</a>
      </span>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
        :currentPage="pagination.current"
        :pageSize="pagination.page_size"
        :total="pagination.total"
        @onChange="onChange"
        @onShowSizeChange="onChange"
    >
    </base-pagination>
    <favorite-groups-model :type="2" ref="favoriteGroupsModel" @getload="getload"></favorite-groups-model>
  </div>
</template>

<script>
import PolymersDealer from "@/components/widgets/polymers-dealer";
import FavoriteGroupsModel from "@/components/widgets/favorite-groups-model";
import moment from "moment";
import _base_https from "@/api/base_api";

const columns = [
  {
    title: "基本信息",
    dataIndex: "aweme_id",
    scopedSlots: {customRender: "awemeId"},
    ellipsis: true,
  },
  {
    title: "点赞量",
    dataIndex: "digg_count",
    scopedSlots: {customRender: "digg_count"},
    align: "center",
    width: 100,
  },
  {
    title: "评论量",
    dataIndex: "comment_count",
    scopedSlots: {customRender: "comment_count"},
    align: "center",
    width: 100,
  },
  {
    title: "转发量",
    dataIndex: "share_count",
    scopedSlots: {customRender: "share_count"},
    align: "center",
    width: 100,
  },
  {
    title: "播放量",
    dataIndex: "play_volume",
    align: "center",
    scopedSlots: {customRender: "play_volume"},
    width: 120,
  },
  // {title: "发布时间", dataIndex: "publish_time", align: "center", width: 120},
  // {title: "平台号", dataIndex: "code", align: "center", width: 100},
  {title: "品牌", dataIndex: "principal_name", align: "center", width: 80},
  {title: "经销商", dataIndex: "dealer_name", align: "center", width: 80},
  {
    title: "操作",
    align: "center",
    scopedSlots: {customRender: "operation"},
    width: 120,
  },
];
export default {
  data() {
    return {
      columns,
      listData: [],
      pagination: {
        current: 1,
        page_size: 10,
        total: 0,
      },
      searchForm: {
        brand_id: "",
        aweme_title: "",
        nickname: "",
        dealer_name: "",
        start_time: moment().subtract(7, "days").format("YYYY-MM-DD") + " 00:00:00",
        end_time: moment().subtract(1, "days").format("YYYY-MM-DD") + " 23:59:59",
        time: [moment().subtract(7, "days").format("YYYY-MM-DD") + " 00:00:00", moment().subtract(1, "days").format("YYYY-MM-DD") + " 23:59:59"],
      },
      layout: {
        labelCol: {span: 5},
        wrapperCol: {span: 16},
      },
      brandSelectList: [],
      loading: false,
      updataTime: "",
      pickerOptions: {
        disabledDate: (current) => {
          return current && current > moment().endOf("day");
        },
      },
      userId: (window.localStorage.getItem("user_id") || ""),
    };
  },
  mounted() {
    this.getDataList(1, 10);
    this.getSubject();
  },
  components: {
    PolymersDealer,
    FavoriteGroupsModel,
  },
  methods: {
    moment,
    // 获取已经收藏的视频
    getFavoritet(data) {
      return _base_https.getFavoritet(this.userId).then((res) => {
        let list = res.list;
        let listData = data.data || [];
        listData.forEach(item => {
          if(list.some(b => b == item.aweme_id)) {
            item.isCollection = 1;
          } else {
            item.isCollection = 0;
          }
        });
        this.listData = listData || [];
        this.pagination = data.pagination;
        this.updataTime = data.data[0].mtime;
      });
    },
    // 获取主体列表
    getSubject() {
      this.$api.core.Polymers.subject().then((res) => {
        if(res.length) {
          this.brandSelectList = res;
        }
      });
    },
    // 获取列表数据
    getDataList(current, page) {
      // let start_time = "";
      // let end_time = "";
      // console.log(typeof this.searchForm.time[0]);
      // if(typeof this.searchForm.time[0] !== "string") {
      //   console.log("isString");
      //   start_time = this.$moment(this.searchForm.time[0]._d).format(
      //       "YYYY-MM-DD HH:mm:ss"
      //   );
      //   end_time = this.$moment(this.searchForm.time[1]._d).format(
      //       "YYYY-MM-DD HH:mm:ss"
      //   );
      // }
      let paylod = {
        size: page,
        current,
        principal_id: this.searchForm.brand_id,
        start_time: this.searchForm.start_time,
        end_time: this.searchForm.end_time,
        nickname: this.searchForm.nickname,
        dealer_name: this.searchForm.dealer_name,
        aweme_title: this.searchForm.aweme_title,
      };
      this.loading = true;
      this.$api.core.Polymers.dealer(paylod)
          .then((res) => {
            this.loading = false;
            this.getFavoritet(res);
          })
          .catch((res) => {
            // console.log(res);
            this.loading = false;
          });
    },
    openVideo(url) {
      if(url) {
        const parm = '\u003cscript\u003elocation.replace("' + url + '")\u003c/script\u003e';
        const str = 'javascript:window.name;'
        window.open(str, parm);
      } else {
        this.$message.warning("链接不存在");
      }
    },
    // 查看详情
    openDetail(id) {
      // console.log("传入id", id);
      this.$router.push({
        name: "polymers:dealer_detail",
        query: {
          id,
        },
      });
    },
    // 收藏视频
    favorite(row) {
      this.$nextTick(() => {
        this.$refs.favoriteGroupsModel.$emit("init", {record: {...row}});
      });
    },
    // 分页事件-修改当前页码、修改当前页数
    onChange(current, pageSize) {
      this.getDataList(current, pageSize);
    },
    goDetail(hot_level, id) {
      this.$router.push({
        name: 'polymers:topic_detail',
        query: {
          hot_level,
          id
        }
      });
    },
    // 重置选择条件
    resetSearch() {
      this.searchForm = {
        brand_id: "",
        dealer_name: "",
        aweme_title: "",
        start_time: moment().subtract(7, "days").format("YYYY-MM-DD") + " 00:00:00",
        end_time: moment().subtract(1, "days").format("YYYY-MM-DD") + " 23:59:59",
        time: [moment().subtract(7, "days").format("YYYY-MM-DD") + " 00:00:00", moment().subtract(1, "days").format("YYYY-MM-DD") + " 23:59:59"],
        nickname: "",
      };
      this.getDataList(1, 10);
    },
    // 搜索
    searchClick() {
      this.getDataList(1, 10);
    },
    //日期选择框
    onChangeDate(dates, dateStrings) {
      // console.log(dates, dateStrings);
      // console.log(dateStrings);
      if(dateStrings) {
        this.searchForm.time = [dateStrings[0], dateStrings[1]];
        this.searchForm.end_time = dateStrings[1];
        this.searchForm.start_time = dateStrings[0];
      } else {
        this.searchForm.end_time = "";
        this.searchForm.start_time = "";
      }
    },
    getload() {
      this.searchClick();
    }
  },
};
</script>
<style lang="less" scoped>
.flex-wrap {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.ml-10p {
  margin-left: 10px;
}

.header_message {
  font-size: 15px;
  font-weight: 500;
  padding-left: 15px;
  padding-bottom: 15px;
  //margin: 8px 0;
  //text-align: right;
}

.updata_times {
  display: inline-block;
  margin: 0 26px;
}

.video_title {
  display: flex;
  //   align-items: center;
  .cover_box {
    display: block;
    height: 118px;
    width: 89px;
    margin-right: 10px;
    flex-shrink: 0;

    img {
      height: 100%;
      width: 100%;
      border-radius: 6px;
    }
  }

  .aweme_img {
    display: block;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin-right: 6px;
    flex-shrink: 0;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .aweme-title {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 22px;
    white-space: pre-wrap;
    font-weight: 500;
  }

  .aweme-dealer {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
  }
}
</style>
