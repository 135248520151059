<template>
  <a-list-item :small="small">
    <a-list-item-meta>
      <template #title>
        <span class="aweme-card-title" :title="wrapValue.title || '-'">{{ wrapValue.title || '-' }}</span>
      </template>
      <a-avatar slot="avatar" :src="wrapValue.cover" class="avator-cover"/>
      <div slot="description" class="card-desc" >
        <span v-if="!small" :title="wrapValue.nickname || '-'">{{ wrapValue.nickname || '-' }}</span>
      </div>
    </a-list-item-meta>
  </a-list-item>
</template>

<script>
const defaultFieldMap = {
  'title':'aweme_title',
  'cover':'aweme_cover',
  'nickname':'nickname',
}

  export default {
    name: "aweme-card",
    props: {
      value: {
        type: Object,
        default: () => {}
      },
      dataType: {
        type: Number,
        default: 1
      },
      small: {
        type: Boolean,
        default: false
      },
      fieldMap:{
        type:Object,
        required:false,
        default:()=> _.cloneDeep(defaultFieldMap)
      },
      timeLabel:{
        type: String,
        required:false,
        default: '发布时间'
      },
      link: {
        type: Boolean,
        default: true
      },
      type: {
        type: String,
        default: 'aweme'
      }
    },
    data: () => ({

    }),
    computed:{
      wrapValue(){
        let fieldMap = Object.assign({}, defaultFieldMap, this.fieldMap), r = {}
        for(let attr in fieldMap){
          let orginKey = fieldMap[attr];
          let val = _.get(this.value, orginKey)
          r[attr]=val;
        }
        return r;
      }
    }
  }
</script>

<style scoped>
.ant-list-item {
  display: block;
}
.ant-list-item-meta-content {
  overflow: hidden;
}
.ant-list-item-meta-title, .card-desc > span {
  /*max-width: 180px;*/
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}
.ant-list-item[small] {
  padding: 0;
}
.ant-list-item .avator-cover{
  width:90px;
  height:120px;
  border-radius: 0;
}
.ant-list-item[small] .avator-cover{
  height:80px;
  border-radius: 0;
}
a.aweme-card-title:hover {
  color: #ff9900;
  cursor: pointer;
}
.card-desc {
  display: flex;
  flex-flow: column;
}
</style>