<!--表单-->
<template slot="footer">
  <a-modal v-model="visible" :title="`收藏${type===1 ? '播主' : '视频'}`"
           :bodyStyle="{maxHeight:'400px',overflow:'auto'}"
           @cancel="cancelVisible"
  >
    <a-form-model :loading="loading" ref="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <!--        <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">全选</a-checkbox>-->
      <a-spin :spinning="spinning" :style="{minHeight:'200px'}" size="large">
        <a-checkbox-group v-model="groupIds">
          <a-row style="padding: 5px 0 5px" v-for="item in groups" :key="item.id">
            <a-checkbox :value="item.id">{{ item.name }}</a-checkbox>
          </a-row>
        </a-checkbox-group>
      </a-spin>
    </a-form-model>
    <template #footer>
      <a-button style="float: left" type="link" icon="folder-add" @click="add">
        新建分组
      </a-button>
      <a-button @click="cancelVisible">
        取消
      </a-button>
      <a-button type="primary" :loading="loading" @click="batchFavorite">
        确定
      </a-button>
      <group-form v-model="visible2" ref="GroupForm" @saved="onGroupSaved" :type="type"></group-form>
    </template>
  </a-modal>
</template>
<style rel="stylesheet/less" scoped lang="less">
</style>
<script>

import GroupForm from "@/components/widgets/group-form";

export default {
  props: {
    type: {
      type: Number,
      required: true,
      default: 1
    },
    isSelectedArrRows: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  components: {GroupForm},
  data() {
    return {
      labelCol: {span: 4},
      wrapperCol: {span: 18},
      visible: false,
      visible2: false,
      groups: [],
      groupIds: [],
      loading: false,
      bizId: "",
      spinning: false,
      selectRecord: {}
    };
  },
  computed: {},
  methods: {
    add() {
      this.visible2 = true;
      this.$nextTick(() => {
        this.$refs.GroupForm.$emit("init", {});
      });
    },
    onGroupSaved() {
      this.load();
    },
    batchFavorite() {
      if(this.groupIds.length) {
        if(this.isSelectedArrRows) {
          const selectedArr = this.selectRecord.selectedArr || [];
          const params = {
            bizIds: selectedArr,
            groupIds: this.groupIds
          };
          this.loading = true;
          this.$api.FavoriteService.batchFavoriteBatch(params).then(() => {
            if(this.selectRecord.selectedArr.length > 1) {
              this.$message.info(`已经成功收藏${ this.selectRecord.selectedArr.length }个播主！`);
            } else {
              this.$message.info("收藏播主成功！");
            }
            this.visible = false;
            this.$emit("getload",);
          }).finally(() => {
            this.loading = false;
          });
        } else {
          this.loading = true;
          this.$api.FavoriteService.batchFavorite(this.bizId, this.groupIds).then(() => {
            this.$message.info("收藏播主成功！");
            this.visible = false;
            this.$emit("getload",);
          }).finally(() => {
            this.loading = false;
          });
        }

      } else {
        this.$message.error("请先选择分组");
      }
    },
    load() {
      this.spinning = true;
      this.groups = [];
      this.$api.FavoriteGroupService.query({
        param: {
          type: this.type,
          isDeleted: false
        }, limit: 100
      }).then(res => {
        this.groups = res.data;
        this.groups.reverse();
        this.spinning = false;
      });
    },
    init(options = {}) {
      this.visible = true;
      this.load();
      this.groupIds = [];
      this.bizId = [];
      if(options.record) {
        this.selectRecord = options.record || {};
        if(!this.isSelectedArrRows) {
          this.bizId = options.record.aweme_id ? options.record.aweme_id : options.record.authorId;
          this.$api.FavoriteService.findByBiz(this.bizId).then(data => {
            this.groupIds = data.map(t => t.groupId);
          });
        }
      }
    },
    cancelVisible() {
      this.visible = false;
      this.$emit("getload",);
    }
  },
  beforeCreate() {

  },
  created() {
  },
  mounted() {
    this.$on("init", this.init);
  }
};
</script>
